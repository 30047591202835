import axios from "axios";
import { baseUrl } from "../../config/config";
import { GET_CATEGORIES, GET_PRODUCT } from "../types";
import Swal from "sweetalert2";

export const getCategoriesThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${baseUrl}/categories`);
        dispatch({
            type: GET_CATEGORIES,
            payload: response.data,
        });
    };
};

export const getProductThunk = (cat_id) => {
    return async (dispatch) => {
        const response = await axios.get(`${baseUrl}/product?cat_id=${cat_id}`);
        dispatch({
            type: GET_PRODUCT,
            payload: response.data,
        });
    };
};

export const ProductPost = (data) => {
    return async (dispatch) => {

        await axios.post(`${baseUrl}/product`, data)
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)

            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const ProductPut = (data) => {
    return async (dispatch) => {

        await axios.post(`${baseUrl}/product/change`, data)
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const ProductDelete = (id) => {
    return async (dispatch) => {

        await axios.delete(`${baseUrl}/product`, { data: { id } })
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}