import axios from "axios";
import Swal from "sweetalert2";
import { baseUrl, getUrl } from "../../config/config";
import { GET_INFO, GET_LINK } from "../types";

export const getInfoThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${baseUrl}/contact`);
        dispatch({
            type: GET_INFO,
            payload: response.data,
        });
    };
};

export const InfoPut = (data) => {
    return async (dispatch) => {
        await axios.post(`${baseUrl}/contact/change`, { ...data })
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const getLinkThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${baseUrl}/link`);
        dispatch({
            type: GET_LINK,
            payload: response.data,
        });
    };
};

export const LinkPut = (data) => {
    return async (dispatch) => {

        await axios.post(`${baseUrl}/link/change`, data)
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const LinkPost = (data) => {
    return async (dispatch) => {

        await axios.post(`${baseUrl}/link`, data)
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const LinkDelete = (id) => {
    return async (dispatch) => {

        await axios.delete(`${baseUrl}/link`, { data: { id } })
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}