import { combineReducers } from "redux";
import { isAuthReducer } from "./reducers/authReducer";
import { aboutUsReducer } from "./reducers/aboutUsReducer";
import { AdminReducer } from "./reducers/adminReducer";
import { infoReducer } from './reducers/infoReducer';
import { videoReducer } from './reducers/videoReducer';
import { servicesReducer } from './reducers/servicesReducer';
import { teachReducer } from './reducers/teachReducer';
import { productReducer } from './reducers/productReducer';


export const rootReducer = combineReducers({
  isAuthReducer,
  aboutUsReducer,
  AdminReducer,
  infoReducer,
  videoReducer,
  servicesReducer,
  teachReducer,
  productReducer
});
