import React from "react";
import { Route, Routes } from "react-router-dom";
import { isAuthPages, isNotAuthPages } from "../../utils/routing/routes";
import { useSelector } from 'react-redux'
// import NotFound from "../../components/404/404";
// import { LOGIN_PAGE } from "../../utils/routing/urls";
// import Login from "../../components/login/Login";
// import { token } from "../../config/config";

const Pages = () => {

  const isAuth = useSelector(state => state.isAuthReducer.isAuth)
  return (
    <Routes>

      {isAuth ?
        <>
          <Route path='/' element={<div style={{ display: 'flex', width: '100%', height: '80vh', justifyContent: 'center', alignItems: 'center' }}><h1>Welcome to Universal Hospital Admin Dashboard</h1></div>} />
          {isAuthPages?.map((i) => {

            return <Route path={i.path} element={<i.Component />} key={i.id} />;

          })}
        </> :

        isNotAuthPages?.map((i) => {
          return <Route path={i.path} element={<i.Component />} key={i.id} />;
        })}


    </Routes>
  );
};

export default Pages;
