export const ABOUTUS_PAGE = '/about-us'
export const VIDEO_PAGE = '/video'
export const NOTFOUND_PAGE = "/404"
export const PRODUCT_PAGE = '/'
export const ADMIN_PAGE = '/admins'
export const COMMERICAL_PAGE = '/commercial'
export const INFO_PAGE = '/info'
export const SERVICES_PAGE = '/services'
export const PARTNERS_PAGE = '/partners'
export const LINK_PAGE = '/link'
export const SCHOOL_SMALL_PAGE = '/schoolsmall'
export const TEAM_PAGE = '/team'
export const SLIDER_PAGE = '/slider'
export const TEACH_PAGE = '/teach'
export const CALL_PAGE = '/call'
export const OPINION_PAGE = '/opinion'
export const GALLERY_PAGE = '/gallery'
export const GALLERY_PAGE_ITEM = '/gallery/:id'
export const LOGIN_PAGE = '/'
export const ARTADRANG_PAGE = '/artadranq'