import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTeachThunk } from "../../store/actions/teachAction";
import CooperateItem from "./item";

const CooperateComponent = () => {
    const dispatch = useDispatch();
    const teach = useSelector(state => state.teachReducer.teach);
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(getTeachThunk())
    }, [])

    useEffect(() => {
        setData(teach)
    }, [teach])






    return (
        <div>
            <h2 mt={3} mb={3} style={{ marginLeft: 10 }}>
                Դասընթացի գրանցում
            </h2>
            {data && <CooperateItem rows={data} />}
        </div>
    )
}

export default CooperateComponent;