import React from 'react';
import "../sidebar/sidebar.scss"
import CopyrightIcon from '@mui/icons-material/Copyright';
const Footer = () => {
    const date = new Date()
    const year = date.getFullYear()
    //className="footerBox"
    return (
        <div >
          {/* <CopyrightIcon/>ARMCODING {year} */}
        </div>
    );
};

export default Footer;