import { GET_INFO, GET_LINK } from "../types"

const initialState = {
    info: {
        data: [{
            email: '',
            address_am: '',
            address_ru: '',
            address_en: '',
            phone_number: '',
            phone_number2: '',
            phone_number3: '',
            phone_number4: '',
            facebook: '',
            instagram: '',
            google_plus: '',
            twitter: '',
        },]
    },
    link: []
}


export const infoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INFO:
            return {
                ...state,
                info: action.payload.data
            }
        case GET_LINK:
            return {
                ...state,
                link: action.payload.data
            }
        default:
            return state
    }
}