import "./sidebar.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthPages, isNotAuthPages } from "../../utils/routing/routes";
import { useLocation } from 'react-router-dom';
import logo from "../../utils/images/logo.png"
import { useState } from "react";

const Sidebar = () => {
    const isAuth = useSelector((state) => state.isAuthReducer.isAuth);
    let location = useLocation();

    const [open, setOpen] = useState([])

    const onOpen = (id) => {
        if (open.includes(id)) {
            const index = open.indexOf(id)
            open.splice(index, 1)
            setOpen([...open])
        } else {
            setOpen([...open, id])
        }
    }


    return (
        <div className="sidebar">
            <div className="top">

                <span className="logo" style={{
                    fontSize: "30px"
                }}>
                    UH
                </span>
            </div>
            <hr />
            <div className="center">
                <ul>
                    {isAuth && isAuthPages.map(({ id, path, name, icon, unlink, Components }) => {
                        return (
                            <div key={id}>
                                {!unlink ? <Link to={path} style={{ textDecoration: "none" }} key={id}
                                    className={location.pathname === path ? "activeLink" : "pasiveLink"}
                                >
                                    <li>
                                        {icon}
                                        {/*<DashboardIcon className="icon"/>*/}
                                        <span style={{
                                            fontSize: "20px"
                                        }}>{name}</span>
                                    </li>
                                </Link>
                                    : null}
                                <hr className="hra" />
                            </div>
                        );
                    })
                    }
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
