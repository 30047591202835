import axios from "axios";
import Swal from "sweetalert2";
import { baseUrl, } from "../../config/config";
import { GET_PARTNERS, GET_SERVICES, GET_SLIDER, GET_TEAM } from "../types";

export const getServicesThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${baseUrl}/service`);
        dispatch({
            type: GET_SERVICES,
            payload: response.data,
        });
    };
};

export const ServicesPut = (data) => {
    return async (dispatch) => {

        await axios.post(`${baseUrl}/service/change`, data)
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const ServicesPost = (data) => {
    return async (dispatch) => {

        await axios.post(`${baseUrl}/service`, data)
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)

            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const ServicesDelete = (id) => {
    return async (dispatch) => {

        await axios.delete(`${baseUrl}/partner`, { data: { id } })
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const getPartnersThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${baseUrl}/partner`);
        dispatch({
            type: GET_PARTNERS,
            payload: response.data,
        });
    };
};

export const PartnersPut = (data) => {
    return async (dispatch) => {

        await axios.post(`${baseUrl}/partner/change`, data)
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const PartnersPost = (data) => {
    return async (dispatch) => {

        await axios.post(`${baseUrl}/partner`, data)
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const PartnersDelete = (id) => {
    return async (dispatch) => {

        await axios.delete(`${baseUrl}/partner`, { data: { id } })
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const getTeamThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${baseUrl}/team`);
        dispatch({
            type: GET_TEAM,
            payload: response.data,
        });
    };
};

export const TeamPut = (data) => {
    return async (dispatch) => {

        await axios.post(`${baseUrl}/team/change`, data)
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const TeamPost = (data) => {
    return async (dispatch) => {

        await axios.post(`${baseUrl}/team`, data)
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const TeamDelete = (id) => {
    return async (dispatch) => {

        await axios.delete(`${baseUrl}/team`, { data: { id } })
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const getSliderThunk = () => {
    return async (dispatch) => {
        const response = await axios.get(`${baseUrl}/slider`);
        dispatch({
            type: GET_SLIDER,
            payload: response.data,
        });
    };
};

export const SliderPut = (data) => {
    return async (dispatch) => {

        await axios.post(`${baseUrl}/slider/change`, data)
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const SliderPost = (data) => {
    return async (dispatch) => {

        await axios.post(`${baseUrl}/slider`, data)
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}

export const SliderDelete = (id) => {
    return async (dispatch) => {

        await axios.delete(`${baseUrl}/slider`, { data: { id } })
            .then(async () => {
                await Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your work has been saved',
                    showConfirmButton: false,
                    timer: 1500
                })
                window.location.reload(false)
            })
            .catch(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }
}