import { GET_CALL, GET_OPINION, GET_TEACH } from "../types"

const initialState = {
    teach: [],
    call: [],
    opinion: []
}


export const teachReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TEACH:
            return {
                ...state,
                teach: action.payload.data
            }
        case GET_CALL:
            return {
                ...state,
                call: action.payload.data
            }
        case GET_OPINION:
            return {
                ...state,
                opinion: action.payload.data
            }
        default:
            return state
    }
}