import { GET_CATEGORY, GET_GALLERY, GET_VIDEO } from "../types"

const initialState = {
    video: [],
    gallery: [],
    category: []
}


export const videoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VIDEO:
            return {
                ...state,
                video: action.payload.data
            }
        case GET_CATEGORY:
            return {
                ...state,
                category: action.payload.data
            }
        case GET_GALLERY:
            return {
                ...state,
                gallery: action.payload
            }
        default:
            return state
    }
}