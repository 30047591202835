import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getOpinionThunk, getTeachThunk } from "../../store/actions/teachAction";
import CooperateItem from "./item";

const OpinionComponent = () => {
    const dispatch = useDispatch();
    const opinion = useSelector(state => state.teachReducer.opinion);
    const [data, setData] = useState([]);
    useEffect(() => {
        dispatch(getOpinionThunk())
    }, [])

    useEffect(() => {
        setData(opinion)
    }, [opinion])




    return (
        <div>
            <h2 mt={3} mb={3} style={{ marginLeft: 10 }}>
                Կարծիքներ
            </h2>
            {data && <CooperateItem rows={data} />}
        </div>
    )
}

export default OpinionComponent;