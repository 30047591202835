import React, { useEffect, useState } from 'react';
import Modal from "@mui/material/Modal";
import { Box, Tab, Tabs, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";


import Button from "@mui/material/Button";
import { useDispatch } from 'react-redux';
import ConvertBase64 from '../../utils/helpers/base64';

import { GalleryPost } from '../../store/actions/videoAction';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 500,
    overflow: "scroll",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const GalleryAddModal = ({ openAdd, setOpenAdd, category }) => {
    const [value, setValue] = useState(0);
    const [image, setImage] = useState()
    const [check, setCheck] = useState(1)
    const dispatch = useDispatch()

    const handleChange2 = (event, newValue) => {
        setValue(newValue);
    };

    const [data, setData] = useState({})

    useEffect(() => {
        setCheck(category?.[0]?.id)
    }, [category])

    const handleChange = (e, k) => {
        data[e.target.name] = e.target.value;
        setData({ ...data })
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("image", image?.files[0])
        formData.append("id", check)
        dispatch(GalleryPost(formData))

        setOpenAdd(false)

    }

    const handleFile = async (e) => {
        const image2 = await ConvertBase64(e);
        data.image = image2;
        setData({ ...data })
        setImage(e.target)
    };
    console.log(image?.files[0], 'asddsasd')
    return (
        <div>
            <Modal
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit}>
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Ավելացնել
                        </Typography>
                        <Box style={{ margin: "10px 0" }}>
                            <Button color="secondary" variant="contained" component="label">
                                Խմբագրել նկարը
                                <input type="file" name="image" required hidden multiple onChange={handleFile} />
                            </Button>
                        </Box>
                        <Box>
                            <div style={{ width: 300 }}>
                                {data.image && <img src={data.image} alt="image" style={{ maxWidth: "100%", maxHeight: 300 }} />}
                            </div>
                        </Box>
                        <Box mt={3}><select defaultValue={category?.[0]?.id} onChange={(e) => setCheck(e.target.value)}>
                            {category?.map((i) => (
                                <option key={i.id} value={i.id}>{i?.title_am}</option>
                            ))}
                        </select>
                        </Box>
                        <Box mt={8}>
                            <Button type='submit' color="secondary" variant="contained">Ավելացնել</Button>
                        </Box>
                    </Box>
                </form>
            </Modal>
        </div>
    );
}

export default GalleryAddModal;