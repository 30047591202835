import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 700,
    overflow: "scroll",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column'
};

const CooperateViewModal = ({ openAdd, setOpenAdd, row, }) => {

    return (
        <div>
            <Modal
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {row && <Box sx={style} >
                    <h3 mt={3} mb={3} style={{ display: 'inline' }}>Անուն - {row?.first_name}</h3>
                    <h3 mt={3} mb={3} style={{ display: 'inline' }}>Ազգանուն - {row?.last_name}</h3>
                    <h3 mt={3} mb={3} style={{ display: 'inline' }}>Էլ․ հասցե - {row?.phone_number}</h3>
                    <h3 mt={3} mb={3} style={{ display: 'inline' }}>Ծառայությոան անվանում - {row?.service}</h3>

                </Box>}
            </Modal>
        </div>
    );
}

export default CooperateViewModal;