import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GalleryDelete, GalleryPut, getCategoryThunk, getGalleryItemThunk, getGalleryThunk } from "../../store/actions/videoAction";
import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import GalleryAddModal from "./galleryAddModal";
import { Button } from "@mui/material";
import { imgUrl } from "../../config/config";
import DeleteModal from "../../utils/helpers/deletemodal";

const Gallery = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const category = useSelector(state => state.videoReducer.category)
    const gallery = useSelector(state => state.videoReducer.gallery)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDel, setOpenDel] = useState(false)

    useEffect(() => {
        dispatch(getCategoryThunk())
    }, [])

    useEffect(() => {
        if (category?.length > 0) {
            if (id) {
                dispatch(getGalleryItemThunk(id))
            } else {
                dispatch(getGalleryThunk())
            }
        }
    }, [id, category])


    return (
        <div style={{ maxWidth: 1340, marginTop: 5 }}>
            <h2 mt={3} mb={3} style={{ marginLeft: 10 }}>
                Պատկերասրահ
            </h2>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%' }}>
                <div className="cat-link">
                    <Link to={`/gallery`} style={!id ? { color: '#12724F' } : { color: 'black' }}>Ամբողջը</Link>
                </div>
                {category?.map((i) => (
                    <div className="cat-link" key={i.id}>
                        <Link to={`/gallery/${i.id}`} style={id == i.id ? { color: '#12724F' } : { color: 'black' }}>{i.title_am}</Link>
                    </div>
                ))}
            </div>
            <div style={{ marginTop: 30, marginLeft: 30 }}>
                <Button color="secondary" variant="contained" onClick={() => setOpenAdd(true)}>
                    Ավելացնել
                </Button>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', flexWrap: 'wrap' }}>
                {gallery?.map((i) => (
                    <div style={{ width: 250, height: 300, borderRadius: 15 }} key={i.id}>
                        <img src={`${imgUrl}/${i?.image}`} style={{ width: 250, height: 250, borderRadius: 15 }} alt='image' />
                        <select defaultValue={i.cat_id} onChange={(e) => dispatch(GalleryPut({ id: i.id, cat_id: e.target.value }))}>
                            {category?.map((z) => (
                                <option key={z.id} value={z.id}>{z?.title_am}</option>
                            ))}
                        </select>

                        <Button color="secondary" variant="contained" onClick={() => setOpenDel(true)}>
                            Ջնջել
                        </Button>
                        <DeleteModal open={openDel} setOpen={setOpenDel} id={i.id} action={GalleryDelete} />
                    </div>
                ))}
            </div>
            <GalleryAddModal openAdd={openAdd} setOpenAdd={setOpenAdd} category={category} />

        </div>);
}

export default Gallery;
