import { GET_ABOUT_US, GET_BANNER } from "../types"

const initialState = {
    aboutUs: [],
    banner: []
}


export const aboutUsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ABOUT_US:
            return {
                ...state,
                aboutUs: action.payload.data
            }
        case GET_BANNER:
            return {
                ...state,
                banner: action.payload.data
            }
        default:
            return state
    }
}