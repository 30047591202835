import { GET_PARTNERS, GET_SERVICES, GET_SLIDER, GET_TEAM } from "../types"

const initialState = {
    services: [],
    partners: [],
    team: [],
    slider: []
}


export const servicesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICES:
            return {
                ...state,
                services: action.payload.data
            }
        case GET_PARTNERS:
            return {
                ...state,
                partners: action.payload.data
            }
        case GET_TEAM:
            return {
                ...state,
                team: action.payload.data
            }
        case GET_SLIDER:
            return {
                ...state,
                slider: action.payload.data
            }
        default:
            return state
    }
}