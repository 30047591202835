import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import "./aboutUs.scss";
import { imgUrl } from "../../config/config";
import a11yProps from "../../utils/helpers/allprops";
import TabPanel from "../../utils/helpers/tabpanel";
import TitleText from "../../utils/helpers/titletext";
import { useDispatch, useSelector } from "react-redux";
import ConvertBase64 from "../../utils/helpers/base64";
import { PartnersDelete, PartnersPut, getPartnersThunk, } from "../../store/actions/servicesAction";
import { Fragment } from "react";
import LinkAddModal from "./linkAddModal";
import DeleteModal from "../../utils/helpers/deletemodal";
import { getLinkThunk, LinkDelete, LinkPut } from "../../store/actions/infoAction";

const Links = () => {
    const [value, setValue] = useState(0);
    const [openAdd, setOpenAdd] = useState(false)
    const [openDel, setOpenDel] = useState(false)
    const [image, setImage] = useState([])
    const dispatch = useDispatch()
    const links = useSelector(state => state.infoReducer.link)
    const [data, setData] = useState([])
    useEffect(() => {
        dispatch(getLinkThunk())
    }, [])
    useEffect(() => {
        setData(links)
    }, [links]);

    const handleChange = (e, k) => {
        if (k || k === 0) {
            data[k][e.target.name] = e.target.value;
            setData([...data])
        } else {
            data[e.target.name] = e.target.value;
            setData({ ...data })
        }

    }

    const handleChange2 = (event, newValue) => {
        setValue(newValue);
    };


    const handleSubmit = async (d, k) => {
        dispatch(LinkPut(d))
    };

    console.log(data)

    return (
        <Box m={3} className="boxHeigth" >
            <Box m={3} >
                <h2 mt={3} mb={3}>
                    Օգտակար հղումներ ֊ կարգավորումներ
                </h2>
                {data?.map((i, k) => (
                    <Fragment key={k} >
                        <h1>{k + 1}</h1>
                        <Box sx={{ width: "100%", maxWidth: 300 }}>


                            <TitleText title="Լինկ" subTitle={i.link_address} handleChange={handleChange}
                                nametitle='link_address' k={k} />

                            <div style={{ display: 'flex', gap: 100 }}>
                                <Button color="secondary" variant="contained" onClick={() => handleSubmit(i, k)}>
                                    ՀԱՍՏԱՏԵԼ
                                </Button>
                                <Button color="secondary" variant="contained" onClick={() => setOpenDel(true)}>
                                    Ջնջել
                                </Button>
                            </div>
                            <DeleteModal open={openDel} setOpen={setOpenDel} id={i.id} action={LinkDelete} />
                        </Box>
                    </Fragment>))}
            </Box>
            <Button color="secondary" variant="contained" onClick={() => setOpenAdd(true)}>
                Ավելացնել
            </Button>
            <LinkAddModal openAdd={openAdd} setOpenAdd={setOpenAdd} />
        </Box >
    );
};

export default Links;