import { TextField } from '@mui/material';
import React from 'react';

const TitleText = ({ title, subTitle, text, desc, handleChange, namedesc, nametitle, k }) => {

    return (
        <>
            {title !== null &&
                <h3 mt={3} mb={3} style={{
                    margin: "10px 10px 10px 0",
                    display: 'inline'
                }}>
                    {title}
                </h3>}
            {subTitle !== undefined && <TextField size="small" name={nametitle} fullWidth={true} value={subTitle} onChange={(e) => handleChange(e, k)} variant="outlined" />}


            {text !== null && <h3 mt={3} mb={3} style={{
                margin: "10px 0 10px 0",
            }}>
                {text}
            </h3>}
            {desc !== undefined && <textarea
                id="w3review"
                name={namedesc}
                rows="6"
                maxLength="2000"
                cols="40"
                className="textareaText"
                value={desc}
                onChange={(e) => handleChange(e, k)}
            />}
        </>
    );
};

export default TitleText;