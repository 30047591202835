import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCallThunk, } from "../../store/actions/teachAction";
import CooperateItem from "./item";

const CallComponent = () => {
    const dispatch = useDispatch();
    const call = useSelector(state => state.teachReducer.call);
    const [data, setData] = useState([]);

    useEffect(() => {
        dispatch(getCallThunk())
    }, [])

    useEffect(() => {
        setData(call)
    }, [call])






    return (
        <div>
            <h2 mt={3} mb={3} style={{ marginLeft: 10 }}>
                Զանգ
            </h2>
            {data && <CooperateItem rows={data} />}
        </div>
    )
}

export default CallComponent;