import axios from "axios";
import Swal from "sweetalert2";
import { baseUrl, } from "../../config/config";
import { GET_ABOUT_US, GET_BANNER } from "../types";

export const getAboutUsThunk = () => {
  return async (dispatch) => {
    const response = await axios.get(`${baseUrl}/about`);
    dispatch({
      type: GET_ABOUT_US,
      payload: response.data,
    });
  };
};

export const AboutPut = (data) => {
  return async (dispatch) => {

    await axios.post(`${baseUrl}/about/change`, data)
      .then(async () => {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(() => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}

export const AboutPost = (data) => {
  return async (dispatch) => {

    await axios.post(`${baseUrl}/about`, data)
      .then(async () => {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        })
        window.location.reload(false)
      })
      .catch(() => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}

export const AboutDelete = (id) => {
  return async (dispatch) => {

    await axios.delete(`${baseUrl}/about`, { params: { id } })
      .then(async () => {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        })
        window.location.reload(false)
      })
      .catch(() => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}

export const getBannerThunk = () => {
  return async (dispatch) => {
    const response = await axios.get(`${baseUrl}/banner`);
    dispatch({
      type: GET_BANNER,
      payload: response.data,
    });
  };
};

export const BannerPut = (data) => {
  return async (dispatch) => {

    await axios.post(`${baseUrl}/banner/change`, data)
      .then(async () => {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(() => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}
