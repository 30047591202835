import { Box, Button, Modal, TextField, TextareaAutosize } from "@mui/material";
import { useDispatch } from "react-redux";
import { OpinionEdit } from "../../store/actions/teachAction";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 500,
    overflow: "scroll",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column'
};

const OpinionEditModal = ({ openAdd, setOpenAdd, row, handleChange }) => {
    const dispatch = useDispatch()
    const handleSubmit = () => {
        setOpenAdd(false)
        dispatch(OpinionEdit(row))
    }

    return (
        <div>
            <Modal
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit}>
                    {row && <Box sx={style} >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginBottom: '20px' }}>
                            <h5 style={{ margin: 0 }}>Անուն</h5>
                            <TextField inputProps={{ style: { padding: "10px 10px" } }} name="first_name" type={'text'} required value={row?.first_name} onChange={handleChange} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginBottom: '20px' }}>
                            <h5 style={{ margin: 0 }}>Ազգանուն</h5>
                            <TextField inputProps={{ style: { padding: "10px 10px" } }} name="last_name" type={'text'} required value={row?.last_name} onChange={handleChange} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginBottom: '20px' }}>
                            <h5 style={{ margin: 0 }}>Էլ․Հասցե</h5>
                            <TextField inputProps={{ style: { padding: "10px 10px" } }} name="email" type={'text'} required value={row?.email} onChange={handleChange} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginBottom: '20px' }}>
                            <h5 style={{ margin: 0 }}>Էլ․Հասցե</h5>
                            <textarea
                                id="w3review"
                                name="message"
                                rows="6"
                                maxLength="2000"
                                cols="40"
                                className="textareaText"
                                value={row?.message}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <Button type='submit' color="secondary" variant="contained">Հաստատել</Button>
                        </div>
                    </Box>}
                </form>
            </Modal>
        </div>
    );
}

export default OpinionEditModal;