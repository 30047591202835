import DashboardIcon from "@mui/icons-material/Dashboard";
import {
    ABOUTUS_PAGE,
    ARTADRANG_PAGE,
    CALL_PAGE,
    GALLERY_PAGE,
    GALLERY_PAGE_ITEM,
    INFO_PAGE,
    LINK_PAGE,
    LOGIN_PAGE,
    OPINION_PAGE,
    PARTNERS_PAGE,
    SCHOOL_SMALL_PAGE,
    SERVICES_PAGE,
    SLIDER_PAGE,
    TEACH_PAGE,
    TEAM_PAGE,
    VIDEO_PAGE,
} from "./urls";
import AboutUs from "../../components/about";
import InfoComponent from "../../components/info/Info";
import Video from "../../components/video";
import Services from "../../components/services";
import Partners from "../../components/partners";
import Links from "../../components/link";
import SchoolDemo from "../../components/schooldemo";
import Team from "../../components/team";
import Slider from "../../components/slider";
import CooperateComponent from "../../components/cooperate";
import OpinionComponent from "../../components/opinion";
import CallComponent from "../../components/call";
import Gallery from "../../components/gallery";
import Login from '../../components/login/Login';
import Product from "../../components/product";


export const isAuthPages = [
    // { id: 1, path: TEACH_PAGE, name: "Դասընթացի գրանցում", Component: CooperateComponent, unlink: false, icon: <DashboardIcon /> },
//    { id: 2, path: CALL_PAGE, name: "Զանգ", Component: CallComponent, unlink: false, icon: <DashboardIcon /> },

    { id: 3, path: ARTADRANG_PAGE, name: "Արտադրանք", Component: Product, unlink: false, icon: <DashboardIcon /> },
    // { id: 4, path: GALLERY_PAGE, name: "Պատկերասրահ", Component: Gallery, unlink: false, icon: <DashboardIcon /> },
    // { id: 5, path: LINK_PAGE, name: "Օգտակար հղումներ", Component: Links, unlink: false, icon: <DashboardIcon /> },
    { id: 6, path: SLIDER_PAGE, name: "Սլայդեր", Component: Slider, unlink: false, icon: <DashboardIcon /> },
    { id: 7, path: PARTNERS_PAGE, name: "Գործընկերներ", Component: Partners, unlink: false, icon: <DashboardIcon /> },
    // { id: 8, path: TEAM_PAGE, name: "Թիմ", Component: Team, unlink: false, icon: <DashboardIcon /> },
    { id: 9, path: INFO_PAGE, name: "Կոնտակտային տվյալներ", Component: InfoComponent, unlink: false, icon: <DashboardIcon /> },
    { id: 10, path: SERVICES_PAGE, name: "Ծառայություններ", Component: Services, unlink: false, icon: <DashboardIcon /> },
    // { id: 11, path: VIDEO_PAGE, name: "Վիդեո", Component: Video, unlink: false, icon: <DashboardIcon /> },
    // { id: 12, path: SCHOOL_SMALL_PAGE, name: "Դպրոց", Component: SchoolDemo, unlink: false, icon: <DashboardIcon /> },
    { id: 13, path: ABOUTUS_PAGE, name: "Մեր մասին ", Component: AboutUs, unlink: false, icon: <DashboardIcon /> },
    // { id: 14, path: GALLERY_PAGE_ITEM, name: "Պատկերասրահ", Component: Gallery, unlink: true, icon: <DashboardIcon /> },
]

export const isNotAuthPages = [
    { id: 1, path: LOGIN_PAGE, Component: Login, },
]
