import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInfoThunk, InfoPut } from "../../store/actions/infoAction";
import "./info.scss";

const InfoComponent = () => {
    const dispatch = useDispatch();
    const info = useSelector((state) => state.infoReducer.info[0]);
    const [data, setData] = useState({
        phone: '',
        email: '',
        address_am: '',
        address_ru: '',
        address_en: '',
        facebook: '',
        instagram: '',
        twitter: '',
        telegram: '',
    },);
    console.log(data, 'asdsad')
    const handleChange = (e) => {
        data[e.target.name] = e.target.value;
        setData({ ...data });
    };

    useEffect(() => {
        dispatch(getInfoThunk());
    }, []);
    useEffect(() => {
        setData(info)
    }, [info])

    const handleEdit = () => {
        dispatch(InfoPut(data))
    }

    return (
        <Box m={3} >
            <h2 mt={3} mb={3}>
                Կոնտակտային տվյալներ ֊ կարգավորումներ
            </h2>
            {data && (
                <Box sx={{ width: "100%" }} className="infoBox">
                    <div>
                        <h4>Էլ․ հասցե</h4>
                        <TextField
                            id="standard-basic"
                            label="Էլ․ հասցե"
                            name="email"
                            value={data.email}
                            onChange={handleChange}
                            variant="standard"
                        />
                    </div>
                    <hr className="hr" />
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 50 }}>
                        <div>
                            <h4>Հասցե հայերեն</h4>
                            <TextField
                                id="standard-basic"
                                label="Հասցե հայերեն"
                                onChange={handleChange}
                                name="address_am"
                                variant="standard"
                                value={data.address_am}
                            />
                        </div>
                        <div>
                            <h4>Հասցե անգլերեն</h4>
                            <TextField
                                id="standard-basic"
                                label="Հասցե անգլերեն"
                                onChange={handleChange}
                                name="address_en"
                                variant="standard"
                                value={data.address_en}
                            />
                        </div>
                        <div>
                            <h4>Հասցե ռուսերեն</h4>
                            <TextField
                                id="standard-basic"
                                label="Հասցե ռուսերեն"
                                onChange={handleChange}
                                name="address_ru"
                                variant="standard"
                                value={data.address_ru}
                            />
                        </div>
                    </div>
                    <hr className="hr" />
                    <div>
                        <h4>Հեռախոսահամար</h4>
                        <TextField
                            id="standard-basic"
                            label="Հեռախոսահամար"
                            name="phone"
                            onChange={handleChange}
                            variant="standard"
                            value={data.phone}
                        />
                    </div>

                    <hr className="hr" />
                    <div>
                        <h4>Facebook</h4>
                        <TextField
                            id="standard-basic"
                            label="facebook"
                            name="facebook"
                            onChange={handleChange}
                            variant="standard"
                            value={data.facebook}
                        />
                    </div>

                    <hr className="hr" />

                    <div>
                        <h4>Instagram</h4>
                        <TextField
                            id="standard-basic"
                            label="instagram"
                            name="instagram"
                            onChange={handleChange}
                            variant="standard"
                            value={data.instagram}
                        />
                    </div>
                    <div>
                        <h4>twitter</h4>
                        <TextField
                            id="standard-basic"
                            label="twitter"
                            name="twitter"
                            onChange={handleChange}
                            variant="standard"
                            value={data.twitter}
                        />
                    </div>
                    <div>
                        <h4>telegram</h4>
                        <TextField
                            id="standard-basic"
                            label="telegram"
                            name="telegram"
                            onChange={handleChange}
                            variant="standard"
                            value={data.telegram}
                        />
                    </div>

                    <hr className="hr" />
                    <div>
                        <Button variant="contained" onClick={handleEdit} color="secondary">
                            ՀԱՍՏԱՏԵԼ
                        </Button>
                    </div>
                </Box>
            )}
        </Box>
    );
};

export default InfoComponent;
