import React, { Fragment, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import "./aboutUs.scss";
import { imgUrl } from "../../config/config";
import a11yProps from "../../utils/helpers/allprops";
import TabPanel from "../../utils/helpers/tabpanel";
import TitleText from "../../utils/helpers/titletext";
import { useDispatch, useSelector } from "react-redux";
import { AboutDelete, AboutPut, getAboutUsThunk } from "../../store/actions/aboutUsAction";
import ConvertBase64 from "../../utils/helpers/base64";
import AboutAddModal from "./aboutAddModal";
import DeleteModal from "../../utils/helpers/deletemodal";

const AboutUs = () => {
    const [value, setValue] = useState(0);
    const [image, setImage] = useState([])
    const [openDel, setOpenDel] = useState(false)

    const [openAdd, setOpenAdd] = useState(false)
    const dispatch = useDispatch()
    const aboutUs = useSelector(state => state.aboutUsReducer.aboutUs)
    const [data, setData] = useState([])
    useEffect(() => {
        dispatch(getAboutUsThunk())
    }, [])
    useEffect(() => {
        setData(aboutUs)
    }, [aboutUs]);

    const handleChange = (e, k) => {
        data[k][e.target.name] = e.target.value;
        setData([...data])

    }

    const handleChange2 = (event, newValue) => {
        setValue(newValue);
    };


    const handleSubmit = async (d, k) => {
        const formData = new FormData()
        if (image?.some(elem => elem.index === k)) {
            const one = image.find(elem => elem.index === k)
            formData.append("image", one?.image?.files?.[0])
        }
        for (let key in d) {
            if (key !== "image") {
                formData.append(key, d[key])
            }
        }
        dispatch(AboutPut(formData))
    };
    const handleFile = async (e, k) => {
        const image2 = await ConvertBase64(e);
        data[k].image = image2;
        setData([...data])
        setImage(prev => [...prev, { image: e.target, index: k }])
    };


    return (
        <Box m={3} className="boxHeigth" >
            <Box m={3} >
                <h2 mt={3} mb={3}>
                    Մեր մասին ֊ կարգավորումներ
                </h2>
                {data?.map((i, k) => (
                    <Fragment key={i.id}>
                        <Box mt={3}>
                            <Box sx={{ width: "100%" }}>
                                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                    <Tabs
                                        value={value}
                                        onChange={handleChange2}
                                        aria-label="basic tabs example"
                                        textColor="secondary"
                                        indicatorColor="secondary"
                                    >
                                        <Tab label="Arm" {...a11yProps(0)} />
                                        <Tab label="En" {...a11yProps(1)} />
                                        <Tab label="Ru" {...a11yProps(2)} />
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <TitleText handleChange={(e) => handleChange(e, k)}
                                        text="Տեքստ" desc={i.description_am} namedesc='description_am' />
                                </TabPanel>

                                <TabPanel value={value} index={1}>
                                    <TitleText handleChange={(e) => handleChange(e, k)}
                                        text="Տեքստ" desc={i.description_en} namedesc='description_en' />
                                </TabPanel>

                                <TabPanel value={value} index={2}>
                                    <TitleText handleChange={(e) => handleChange(e, k)}
                                        text="Տեքստ" desc={i.description_ru} namedesc='description_ru' />
                                </TabPanel>
                            </Box>
                            <Box>
                                <div >
                                    <img src={!image.some(elem => elem.index === k) ? `${imgUrl}/${i?.image}` : i?.image} alt="image" style={{
                                        maxWidth: 500, maxHeight: 300,
                                    }} />
                                    <Button color="secondary" variant="contained" component="label" style={{
                                        margin: "0 17px 35px 43px"
                                    }}>
                                        Խմբագրել նկարը
                                        <input type="file" hidden multiple onChange={(e) => handleFile(e, k)} />
                                    </Button>
                                </div>
                            </Box>
                            <div style={{ display: 'flex', gap: 100 }}>
                                <Button color="secondary" variant="contained" onClick={() => handleSubmit(i, k)}>
                                    ՀԱՍՏԱՏԵԼ
                                </Button>
                                <Button color="secondary" variant="contained" onClick={() => setOpenDel(true)}>
                                    Ջնջել
                                </Button>
                            </div>
                            <DeleteModal open={openDel} setOpen={setOpenDel} id={i.id} action={AboutDelete} />
                        </Box >
                    </Fragment>))}
                <Box mt={2}>
                    <Button color="secondary" variant="contained" onClick={() => setOpenAdd(true)}>
                        Ավելացնել
                    </Button>
                </Box>
                <AboutAddModal openAdd={openAdd} setOpenAdd={setOpenAdd} />
            </Box>
        </Box>);
};

export default AboutUs;