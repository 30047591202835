import "./style/dark.scss";
import { useContext, useEffect, useLayoutEffect } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import Home from "./layouts/Home/Home";
import { useDispatch } from "react-redux"
import { token } from "./config/config";
import { thchangeAuAC } from "./store/actions/authAction";
import { useLocation } from "react-router-dom";
import { createMuiTheme, ThemeProvider, createTheme } from "@mui/material";


const theme = createTheme({
    palette: {
        secondary: {
            main: '#12724F'
        }
    }
});


function App() {
    const dispatch = useDispatch()
    const { darkMode } = useContext(DarkModeContext);
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        if (localStorage.getItem("autorization-token")) {
            dispatch(thchangeAuAC(true))
            return
        }
        return
    }, [])



    return (
        <ThemeProvider theme={theme}>
            <div className={darkMode ? "app dark" : "app"}>
                <Home />
            </div>
        </ThemeProvider>
    );
}

export default App;
