export const CHANGE_AUTH = "CHANGE_AUTH";
export const GET_USERS = "GET_USERS";
export const GET_ABOUT_US = "GET_ABOUT_US";
export const GET_ADMINS = 'GET_ADMINS'
export const GET_INFO = 'GET_INFO'
export const GET_LINK = 'GET_LINK'
export const GET_VIDEO = 'GET_VIDEO'
export const GET_SERVICES = 'GET_SERVICES'
export const GET_PARTNERS = 'GET_PARTNERS'
export const GET_BANNER = 'GET_BANNER'
export const GET_TEAM = 'GET_TEAM'
export const GET_SLIDER = 'GET_SLIDER'
export const GET_TEACH = 'GET_TEACH'
export const GET_CALL = 'GET_CALL'
export const GET_OPINION = 'GET_OPINION'
export const GET_CATEGORY = 'GET_CATEGORY'
export const GET_GALLERY = 'GET_GALLERY'
export const CHANGE_LOGIN_CREDENTIALS = 'CHANGE_LOGIN_CREDENTIALS'
export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_CATEGORIES = 'GET_CATEGORIES' 