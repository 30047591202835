import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import "./aboutUs.scss";
import { imgUrl } from "../../config/config";
import a11yProps from "../../utils/helpers/allprops";
import TabPanel from "../../utils/helpers/tabpanel";
import TitleText from "../../utils/helpers/titletext";
import { useDispatch, useSelector } from "react-redux";
import ConvertBase64 from "../../utils/helpers/base64";
import { VideoPut, getVideoThunk } from "../../store/actions/videoAction";

const Video = () => {
    const [value, setValue] = useState(0);
    const [image, setImage] = useState()
    const dispatch = useDispatch()
    const video = useSelector(state => state.videoReducer.video[0])
    const [data, setData] = useState({})
    useEffect(() => {
        dispatch(getVideoThunk())
    }, [])
    useEffect(() => {
        setData(video)
    }, [video]);

    const handleChange = (e) => {
        data[e.target.name] = e.target.value;
        setData({ ...data })

    }

    const handleChange2 = (event, newValue) => {
        setValue(newValue);
    };


    const handleSubmit = async (d) => {
        const formData = new FormData()
        // if (image) {
        //     formData.append("video", image?.files[0])
        // }
        for (let key in d) {
            if (key !== "video") {
                formData.append(key, d[key])
            }
        }
        dispatch(VideoPut(formData))
    };
    const handleFile = async (e) => {
        const image2 = await ConvertBase64(e);
        data.image = image2;
        setData({ ...data })
        setImage(e.target)
    };

    return (
        <Box m={3} className="boxHeigth" >
            <Box m={3} >
                <h2 mt={3} mb={3}>
                    Վիդեո ֊ կարգավորումներ
                </h2>
                {data && <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={value}
                            onChange={handleChange2}
                            aria-label="basic tabs example"
                            textColor="secondary"
                            indicatorColor="secondary"
                        >
                            <Tab label="Arm" {...a11yProps(0)} />
                            <Tab label="En" {...a11yProps(1)} />
                            <Tab label="Ru" {...a11yProps(2)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <TitleText title="Վերնագիր" subTitle={data.title_am} handleChange={handleChange}
                            text="Տեքստ" desc={data.description_am} namedesc='description_am' nametitle='title_am' />
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <TitleText title="Վերնագիր" subTitle={data.title_en} handleChange={handleChange}
                            text="Տեքստ" desc={data.description_en} namedesc='description_en' nametitle='title_en' />
                    </TabPanel>

                    <TabPanel value={value} index={2}>
                        <TitleText title="Վերնագիր" subTitle={data.title_ru} handleChange={handleChange}
                            text="Տեքստ" desc={data.description_ru} namedesc='description_ru' nametitle='title_ru' />
                    </TabPanel>
                </Box>}
                {/* <Box>
                    <div >
                        <img src={!image ? `${imgUrl}/${data?.video}` : data?.video} alt="image" style={{
                            maxWidth: 500, maxHeight: 300,
                        }} />
                        <Button color="secondary" variant="contained" component="label" style={{
                            margin: "0 17px 35px 43px"
                        }}>
                            Խմբագրել նկարը
                            <input type="file" hidden multiple onChange={handleFile} />
                        </Button>
                    </div>
                </Box> */}
                <Button color="secondary" variant="contained" onClick={() => handleSubmit(data)}>
                    ՀԱՍՏԱՏԵԼ
                </Button>
            </Box>
        </Box>
    );
};

export default Video;