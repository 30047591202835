import axios from "axios";
import Swal from "sweetalert2";
import { baseUrl, getUrl } from "../../config/config";
import { GET_CATEGORY, GET_GALLERY, GET_VIDEO } from "../types";

export const getVideoThunk = () => {
  return async (dispatch) => {
    const response = await axios.get(`${baseUrl}/slider`);
    dispatch({
      type: GET_VIDEO,
      payload: response.data,
    });
  };
};

export const VideoPut = (data) => {
  return async (dispatch) => {

    await axios.post(`${baseUrl}/slider/change`, data)
      .then(async () => {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch((e) => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Oops...',
          text: e?.message,
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}

export const getCategoryThunk = () => {
  return async (dispatch) => {
    const response = await axios.get(`${baseUrl}/picture/categories`);
    dispatch({
      type: GET_CATEGORY,
      payload: response.data,
    });
  };
};

export const getGalleryThunk = () => {
  return async (dispatch) => {
    const response = await axios.get(`${baseUrl}/gallery`);
    dispatch({
      type: GET_GALLERY,
      payload: response.data,
    });
  };
};

export const getGalleryItemThunk = (id) => {
  return async (dispatch) => {
    const response = await axios.get(`${getUrl}/gallery/current?id=${id}`);
    dispatch({
      type: GET_GALLERY,
      payload: response.data.data,
    });
  };
};

export const GalleryPut = (data) => {
  return async (dispatch) => {

    await axios.post(`${baseUrl}/gallery/change`, data)
      .then(async () => {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch((e) => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Oops...',
          text: e?.message,
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}

export const GalleryPost = (data) => {
  return async (dispatch) => {

    await axios.post(`${baseUrl}/gallery`, data)
      .then(async () => {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        })
        window.location.reload(false)
      })
      .catch(() => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}

export const GalleryDelete = (id) => {
  return async (dispatch) => {

    await axios.delete(`${baseUrl}/gallery`, { data: { id } })
      .then(async () => {
        await Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        })
        window.location.reload(false)
      })
      .catch(() => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
          showConfirmButton: false,
          timer: 1500
        })
      })
  }
}
