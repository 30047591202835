import { GET_CATEGORIES, GET_PRODUCT } from "../types"

const initialState = {
    product: [],
    categories: []
}


export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PRODUCT:
            return {
                ...state,
                product: action.payload.data
            }
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload.data
            }
        default:
            return state
    }
}