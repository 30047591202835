import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ConvertBase64 from "../../utils/helpers/base64";
import DeleteModal from "../../utils/helpers/deletemodal";
import { ProductDelete, getCategoriesThunk, getProductThunk } from "../../store/actions/productAction";
import ProductAddModal from "./productAddModal";
import { imgUrl } from "../../config/config";
import ProductEditModal from "./productEditModal";

const Product = () => {
    const [value, setValue] = useState(1);
    const [image, setImage] = useState([])
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const categories = useSelector(state => state.productReducer.categories)
    const product = useSelector(state => state.productReducer.product)
    const [openAdd, setOpenAdd] = useState(false)
    const [openDel, setOpenDel] = useState(false)
    const [openEdit, setOpenEdit] = useState(false)
    const [row, setRow] = useState()

    useEffect(() => {
        dispatch(getCategoriesThunk())
    }, [])

    useEffect(() => {
        dispatch(getProductThunk(value))
    }, [value])
    console.log(row)
    return (
        <Box m={3} className="boxHeigth" >
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {categories.map((i, k) => (
                    <h3 key={i.id} style={value === i.id ? { cursor: 'pointer', color: '#12724F' } : { cursor: 'pointer', color: 'black' }} onClick={() => setValue(i.id)}>{i.title_am}</h3>
                ))}
            </div>
            <div style={{ margin: '10px 0' }}>
                <Button color="secondary" variant="contained" onClick={() => setOpenAdd(true)}>Ավելացնել</Button>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 50 }}>
                {product?.map((i) => (
                    <div key={i.id}>
                        <h4 style={{ maxWidth: 300, margin: '5px 0' }}>{i.title_am}</h4>
                        <div style={{ margin: '5px 0' }}>
                            <img src={`${imgUrl}/${i.image}`} style={{ width: 200, height: 200 }} alt="product" />
                        </div>
                        <div style={{ display: 'flex', gap: 50 }}>
                            <Button color="secondary" variant="contained" onClick={() => {
                                setRow(i)
                                setOpenEdit(true)
                            }}>
                                Խմբագրել
                            </Button>
                            <Button color="secondary" variant="contained" onClick={() => setOpenDel(true)}>
                                Ջնջել
                            </Button>
                        </div>
                        <DeleteModal open={openDel} setOpen={setOpenDel} id={i.id} action={ProductDelete} />

                    </div>
                ))}
            </div>
            <ProductAddModal openAdd={openAdd} setOpenAdd={setOpenAdd} />
            {row && <ProductEditModal openAdd={openEdit} setOpenAdd={setOpenEdit} row={row} unid={value} />}
        </Box>);
};

export default Product;