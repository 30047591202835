import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import "./aboutUs.scss";
import { imgUrl } from "../../config/config";
import a11yProps from "../../utils/helpers/allprops";
import TabPanel from "../../utils/helpers/tabpanel";
import TitleText from "../../utils/helpers/titletext";
import { useDispatch, useSelector } from "react-redux";
import ConvertBase64 from "../../utils/helpers/base64";
import { PartnersDelete, PartnersPut, getPartnersThunk, } from "../../store/actions/servicesAction";
import { Fragment } from "react";
import PartnerAddModal from "./partnerAddModal";
import DeleteModal from "../../utils/helpers/deletemodal";

const Partners = () => {
    const [value, setValue] = useState(0);
    const [openAdd, setOpenAdd] = useState(false)
    const [openDel, setOpenDel] = useState(false)
    const [image, setImage] = useState([])
    const dispatch = useDispatch()
    const partners = useSelector(state => state.servicesReducer.partners)
    const [data, setData] = useState([])
    useEffect(() => {
        dispatch(getPartnersThunk())
    }, [])
    useEffect(() => {
        setData(partners)
    }, [partners]);

    const handleChange = (e, k) => {
        if (k || k === 0) {
            data[k][e.target.name] = e.target.value;
            setData([...data])
        } else {
            data[e.target.name] = e.target.value;
            setData({ ...data })
        }

    }

    const handleChange2 = (event, newValue) => {
        setValue(newValue);
    };


    const handleSubmit = async (d, k) => {
        const formData = new FormData()
        if (image.some(elem => elem.index === k)) {
            const one = image.find(elem => elem.index === k)
            formData.append("image", one?.image?.files?.[0])
        }
        for (let key in d) {
            if (key !== "image") {
                formData.append(key, d[key])
            }
        }
        dispatch(PartnersPut(formData))
    };
    const handleFile = async (e, k) => {
        const image2 = await ConvertBase64(e);
        data[k].image = image2;
        setData([...data])
        setImage(prev => [...prev, { image: e.target, index: k }])
    };

    return (
        <Box m={3} className="boxHeigth" >
            <Box m={3} >
                <h2 mt={3} mb={3}>
                    Գործընկերներ ֊ կարգավորումներ
                </h2>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {data?.map((i, k) => (
                        <Fragment key={k} >
                            <h2>{k + 1}</h2>
                            <Box sx={{ width: "100%" }}>
                                <Box>
                                    <div>
                                        <img src={!image.some(elem => elem.index === k) ? `${imgUrl}/${i?.image}` : i?.image} alt="image" style={{
                                            maxWidth: 200, maxHeight: 200,
                                        }} />
                                        <Button color="secondary" variant="contained" component="label" style={{
                                            margin: "0 17px 35px 43px"
                                        }}>
                                            Խմբագրել նկարը
                                            <input type="file" hidden multiple onChange={(e) => handleFile(e, k)} />
                                        </Button>
                                    </div>
                                </Box>
                                <div style={{ display: 'flex', gap: 100 }}>
                                    <Button color="secondary" variant="contained" onClick={() => handleSubmit(i, k)}>
                                        ՀԱՍՏԱՏԵԼ
                                    </Button>
                                    <Button color="secondary" variant="contained" onClick={() => setOpenDel(true)}>
                                        Ջնջել
                                    </Button>
                                </div>
                                <DeleteModal open={openDel} setOpen={setOpenDel} id={i.id} action={PartnersDelete} />
                            </Box>
                        </Fragment>))}
                </div>
            </Box>
            <Button color="secondary" variant="contained" onClick={() => setOpenAdd(true)}>
                Ավելացնել
            </Button>
            <PartnerAddModal openAdd={openAdd} setOpenAdd={setOpenAdd} />
        </Box>
    );
};

export default Partners;