import React, { useEffect, useState } from 'react';
import Modal from "@mui/material/Modal";
import { Box, Tab, Tabs, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";

import axios from "axios";
import { baseUrl, token } from "../../config/config";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from 'react-redux';
import ConvertBase64 from '../../utils/helpers/base64';
import a11yProps from '../../utils/helpers/allprops';
import TabPanel from '../../utils/helpers/tabpanel';
import TitleText from '../../utils/helpers/titletext';
import Both from '../../utils/helpers/both';
import { PartnersPost, ServicesPost, TeamPost } from '../../store/actions/servicesAction';
import OnlyTitle from '../../utils/helpers/onlytitle';
import { AboutPost } from '../../store/actions/aboutUsAction';
import OnlyText from '../../utils/helpers/onlytext';
import { ProductPost } from '../../store/actions/productAction';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 500,
    overflow: "scroll",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const ProductAddModal = ({ openAdd, setOpenAdd }) => {
    const [value, setValue] = useState(0);
    const [image, setImage] = useState()
    const [variant, setVariant] = useState('0')
    const dispatch = useDispatch()
    const categories = useSelector(state => state.productReducer.categories)

    const handleChange2 = (event, newValue) => {
        setValue(newValue);
    };

    const [data, setData] = useState({
        title_am: '',
        title_ru: '',
        title_en: '',
        description_am: '',
        description_ru: '',
        description_en: '',
        cat_id: '1',
        steril: '0',
        width: '',
        height: '',
        width2: '',
        height2: '',
        width3: '',
        height3: '',
        layer: '',
        thing: '',
    })



    const handleChange = (e, k) => {
        data[e.target.name] = e.target.value;
        setData({ ...data })
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData()

        if (image) {
            formData.append("image", image?.files[0])
        }
        for (let key in data) {
            if (key !== "image") {
                formData.append(key, data[key])
            }
        }

        dispatch(ProductPost(formData))
        setOpenAdd(false)

    }

    const handleFile = async (e) => {
        const image2 = await ConvertBase64(e);
        data.image = image2;
        setData({ ...data })
        setImage(e.target)
    };

    console.log(data)

    return (
        <div>
            <Modal
                open={openAdd}
                onClose={() => setOpenAdd(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <form onSubmit={handleSubmit}>
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Ավելացնել
                        </Typography>
                        <Box sx={{ width: "100%" }}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange2}
                                    aria-label="basic tabs example"
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                >
                                    <Tab label="Hy" {...a11yProps(0)} />
                                    <Tab label="En" {...a11yProps(1)} />
                                    <Tab label="Ru" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Both title="Վերնագիր" subTitle={data.title_am} handleChange={handleChange}
                                    nametitle='title_am' text='Տեքստ' desc={data.description_am} namedesc='description_am' />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Both title="Վերնագիր" subTitle={data.title_en} handleChange={handleChange}
                                    nametitle='title_en' text='Տեքստ' desc={data.description_en} namedesc='description_en' />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <Both title="Վերնագիր" subTitle={data.title_ru} handleChange={handleChange}
                                    nametitle='title_ru' text='Տեքստ' desc={data.description_ru} namedesc='description_ru' />
                            </TabPanel>
                        </Box>
                        <Box marginY={2}>
                            <h4 style={{ margin: '5px 0' }}>Կատեգորիա</h4>
                            <select value={data.cat_id} name='cat_id' style={{ fontSize: 15 }} onChange={handleChange}>
                                {categories.map((i) => (
                                    <option value={i.id} key={i.id}>{i.title_am}</option>
                                ))}
                            </select>
                        </Box>
                        <Box marginY={2}>
                            <h4 style={{ margin: '5px 0' }}>Ստերիլ</h4>
                            <select value={data.steril} name='steril' style={{ fontSize: 15 }} onChange={handleChange}>
                                <option value='0' >Ոչ ստերիլ</option>
                                <option value='1' >Ստերիլ</option>
                            </select>
                        </Box>
                        <Box marginY={2}>
                            <div style={{ display: 'flex', gap: 20, margin: '10px 0' }}>
                                <h4 style={{ margin: '5px 0' }}>Չափեր</h4>
                                <select onChange={(e) => {
                                    data.width2 = ''
                                    data.height2 = ''
                                    data.width3 = ''
                                    data.height3 = ''
                                    data.layer = ''
                                    data.thing = ''
                                    setData({ ...data })
                                    setVariant(e.target.value)
                                }} >
                                    <option value="0">Տարբերակ 1</option>
                                    <option value="1">Տարբերակ 2</option>
                                </select>
                            </div>
                            <div style={{ display: 'flex', gap: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                    <input name='width' style={{ fontSize: 14, padding: '3px', width: 150 }} value={data.width} onChange={handleChange} required placeholder='Երկարություն' />
                                    <input name='height' style={{ fontSize: 14, padding: '3px', width: 150 }} value={data.height} onChange={handleChange} required placeholder='Լայնություն' />
                                </div>
                                {variant === '0' ? <>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                        <input name='width2' style={{ fontSize: 14, padding: '3px', width: 150 }} value={data.width2} onChange={handleChange} required placeholder='Երկարություն 2' />
                                        <input name='height2' style={{ fontSize: 14, padding: '3px', width: 150 }} value={data.height2} onChange={handleChange} required placeholder='Լայնություն 2' />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                        <input name='width3' style={{ fontSize: 14, padding: '3px', width: 150 }} value={data.width3} onChange={handleChange} required placeholder='Երկարություն 3' />
                                        <input name='height3' style={{ fontSize: 14, padding: '3px', width: 150 }} value={data.height3} onChange={handleChange} required placeholder='Լայնություն 3' />
                                    </div>
                                </> : <>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                                        <input name='layer' type={'number'} style={{ fontSize: 14, padding: '3px', width: 150 }} value={data.layer} required onChange={handleChange} placeholder='Շերտ' />
                                        <input name='thing' type={'number'} style={{ fontSize: 14, padding: '3px', width: 150 }} value={data.thing} required onChange={handleChange} placeholder='Քանակ' />
                                    </div>
                                </>}
                            </div>
                        </Box>
                        <Box style={{ margin: "10px 0" }}>
                            <Button color="secondary" variant="contained" component="label">
                                Խմբագրել նկարը
                                <input type="file" hidden multiple onChange={handleFile} required />
                            </Button>
                        </Box>
                        <Box>
                            <div style={{ width: 260 }}>
                                {data.image && <img src={data.image} alt="image" style={{ width: "100%", }} />}
                            </div>
                        </Box>
                        <Box>
                            <Button type='submit' color="secondary" variant="contained">Ավելացնել</Button>
                        </Box>
                    </Box>
                </form>
            </Modal>
        </div>
    );
}

export default ProductAddModal;